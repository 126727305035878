
import Header from "../assets/header";
import Carousel from "../assets/carousel"

const Home = () => {

    return(
        <div>
                <Header />
                <Carousel />
        </div>
    )

};


export default Home;